/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:20f09436-2550-45ec-ae21-878f84dee2bd",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_w0FXaYPPk",
    "aws_user_pools_web_client_id": "74l2atb3544faicld42hoa3chh",
    "oauth": {},
    "aws_user_files_s3_bucket": "felix-files133604-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
